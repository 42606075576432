import AniLink from "gatsby-plugin-transition-link/AniLink"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"

import "./header.css"
import moreIcon from "./../../images/icons/bx-dots-vertical-rounded.svg"

const Header = ({ siteTitle }) => {
  const [isActive, setActive] = useState(false)

  return (
    <header>
      <AniLink to="/" className="logo">
        <StaticImage
          src="./../../images/heisentech-logo.png"
          height={56}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          placeholder="none"
          alt="Heisentech logo"
        />
      </AniLink>
      <ul className={`navs ${isActive ? "expanded" : ""}`}>
        <li>
          <AniLink
            onClick={() => setActive(!isActive)}
            swipe
            direction="down"
            to="/"
            className="nav-link"
            activeClassName="active"
          >
            Home
          </AniLink>
        </li>
        <li>
          <AniLink
            onClick={() => setActive(!isActive)}
            swipe
            direction="down"
            to="/stories"
            className="nav-link"
            activeClassName="active"
          >
            Our stories
          </AniLink>
        </li>
        <li>
          <AniLink
            onClick={() => setActive(!isActive)}
            swipe
            direction="down"
            to="/services"
            className="nav-link"
            activeClassName="active"
          >
            Services
          </AniLink>
        </li>
        <li>
          <AniLink
            onClick={() => setActive(!isActive)}
            swipe
            direction="down"
            to="/about-us"
            className="nav-link"
            activeClassName="active"
          >
            About us
          </AniLink>
        </li>
        <li>
          <AniLink
            onClick={() => setActive(!isActive)}
            swipe
            direction="down"
            to="/careers"
            className="nav-link"
            activeClassName="active"
          >
            Careers
          </AniLink>
          <li>
            <AniLink
              onClick={() => setActive(!isActive)}
              swipe
              direction="down"
              to="https://blogs.heisentech.com/"
              className="nav-link"
              activeClassName="active"
            >
              Blogs
            </AniLink>
          </li>
        </li>
      </ul>
      <a className="nav-btn" href="https://cal.com/nikhil01/15min" target="_blank">
        Talk to us
      </a>
      <button className="menu-btn" onClick={() => setActive(!isActive)}>
        <img src={moreIcon} width="22px" alt="Navbar options" />
      </button>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
