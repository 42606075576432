import AniLink from "gatsby-plugin-transition-link/AniLink"
import React from "react"
import { StaticImage } from 'gatsby-plugin-image'

import arrowIcon from './../../images/icons/bx-chevron-right.svg'
import emailIcon from './../../images/icons/bxs-envelope.svg'
import linkedInIcon from './../../images/icons/bxl-linkedin.svg'

import './footer.css'
const Footer = () => (
  <section className="section footer">
    <div className="container big contact-us">
      <div className="col two">
        <AniLink to="/" className="logo">
          <StaticImage
            src="./../../images/heisentech-logo.png"
            height={56}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            placeholder="none"
            alt="Heisentech logo"
            style={{ marginRight: '0.5rem' }}
          />
        </AniLink>
        <p className="desc text">
          We believe every owner or business leader knows what is needed to better their business. If you are amongst those people, and want digital tools to get you there, we are here to assist you. If you are not aware of which processes in your business can be transformed, worry not, we will assist you. Either way, let's have a consultation call.
        </p>
      </div>
      <div className="col two">
        <div className="contact-row">
          <p className="text address"><b>Mumbai, India</b> <br />Shivdarshan CHS, Bhandup(W), Mumbai - 400078.</p>
        </div>
        <div className="contact-row icons">
          <a href="https://www.linkedin.com/company/heisentech-solutions/" className="text" target="_blank" rel="noreferrer">
            <img src={linkedInIcon} alt="LinkedIn" />
          </a>
          <a href="mailto:info@heisentech.com" className="text with-icon" rel="noreferrer">
            <img src={emailIcon} alt="Email" />
            info@heisentech.com
          </a>
        </div>
      </div>
      <div className="col">
        <ul className="links">
          <li className="link-wrapper">
            <img src={arrowIcon} alt="" />
            <AniLink to="/" className="link">Home</AniLink>
          </li>
          <li className="link-wrapper">
            <img src={arrowIcon} alt="" />
            <AniLink to="/services" className="link">Our services</AniLink>
          </li>
          <li className="link-wrapper">
            <img src={arrowIcon} alt="" />
            <AniLink to="/stories" className="link">Our stories</AniLink>
          </li>
          <li className="link-wrapper">
            <img src={arrowIcon} alt="" />
            <AniLink to="/about-us" className="link">About us</AniLink>
          </li>
          <li className="link-wrapper">
            <img src={arrowIcon} alt="" />
            <AniLink to="/careers" className="link">Careers</AniLink>
          </li>
          <li className="link-wrapper">
            <img src={arrowIcon} alt="" />
            <AniLink to="/policies" className="link">Policies</AniLink>
          </li>
        </ul>
      </div>
    </div>
    <div className="copy-text">
      <div className="container big">
        &copy;2023 All right reserved. heisentech solutions pvt. ltd.
      </div>
    </div>
  </section>
)

export default Footer
